import { useContext, useEffect } from "react";
import { Store } from "../Store";
import Button from "react-bootstrap/Button";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import API from "../components/API";
import { Helmet } from "react-helmet";
import Table from "react-bootstrap/Table";
import { useState } from "react";

import cartTabler from "../components/CartTable";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import Popup from "../components/Popup";
import PopupClear from "../components/Popup-Clear";

const api_url = API();

function orderText(cart) {
	let readable = [];
	// const customerInfo = getCustomerInfo();

	for (let i = 0; i < cart.length; i++) {
		let tmp = [];
		tmp.push(cart[i].sku);
		tmp.push(cart[i].item_name);
		tmp.push(cart[i].quantity);
		tmp.sort(function (a, b) {
			return a[0] - b[0];
		});
		readable.push(tmp);
	}
	// readable.push(customerInfo);
	return JSON.stringify(readable, null, 4);
}

export default function CartScreen() {
	const navigate = useNavigate();

	//get customer info from form
	function getCustomerInfo() {
		// Cause JS doesn't support f strings or any kind of string formatting.
		let address = document.getElementsByName("address")[0].value;
		let city = document.getElementsByName("city")[0].value;
		let state = document.getElementsByName("state")[0].value;
		let zip = document.getElementsByName("zip_code")[0].value;

		let final_address = address + ", " + city + ", " + state + " " + zip;
		const customerInfo = [
			{
				Contact_Name: document.getElementsByName("contact_name")[0].value,
				Store_Name: document.getElementsByName("store_name")[0].value,
				Email: document.getElementsByName("email")[0].value,
				Phone_Number: document.getElementsByName("phone_number")[0].value,
				Address: final_address,
			},
		];
		return customerInfo;
	}

	async function sendEmail(e) {
		document.getElementsByName("invoice")[0].value = orderText(cartItems); //set the invoice field to the order text
		document.getElementsByName("subtotal")[0].value = subtotal(); //set the subtotal field to the subtotal
		e.preventDefault(); //prevents the page from refreshing
		//console.log("sending email");

		emailjs.sendForm("gmail", "template_efy4yfj", e.target, "JbAH_8vx7P3bdnZsP") //send the form to emailjs
			.then(
				(result) => {
					ctxDispatch({ type: "CART_CLEAR" });
					localStorage.removeItem("cartItems");
					navigate("/OrderSubmission");
				},
				(error) => {
					//console.log(error.text);
					alert("Something went wrong. Our developers will take a look at the log file and fix this ASAP.");
					return;
				}
			);
	}
	function clearCartHandler() {
		ctxDispatch({ type: "CART_CLEAR" });
		localStorage.removeItem("cartItems");
	}

	function subtotal() {
		return cartItems.reduce((a, c) => a + c.retail_price * c.discount * c.quantity, 0).toFixed(2);
	}

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const {
		cart: { cartItems },
	} = state;

	//Moved to CartTable.js
	/*   const updateCartHandler = async (item, quantity) => {
    const { data } = await axios.get(
      `${api_url}/api/db/products/${item.product_id}`);
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...item, quantity },
    });
    setDiscounts();
  };
  const removeItemHandler = (item) => {
    ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
    setDiscounts();
    console.log("removed item set discounts");
  }; */

	function phoneNumberFormatter() {
		var x = document.getElementById("phone-number");
		x.value = x.value.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
	}
	//Find the distinct categories in the cart
	function getDistinctCategories() {
		let categories = [];
		for (let i = 0; i < cartItems.length; i++) {
			if (!categories.includes(cartItems[i].category)) {
				categories.push(cartItems[i].category);
			}
		}
		return categories;
	}

	//Find how many items are in each category
	function categoryCount() {
		let categories = getDistinctCategories();
		let counts = [];
		for (let i = 0; i < categories.length; i++) {
			let count = 0;
			for (let j = 0; j < cartItems.length; j++) {
				if (categories[i] === cartItems[j].category) {
					count += parseInt(cartItems[j].quantity); //add the quantity of each item in the category
				}
			}
			counts.push(count);
		}
		//pass each category to the getItemsInCategory
		let items = [];
		for (let i = 0; i < categories.length; i++) {
			items.push(getItemsInCategory(categories[i]));
		}
		return counts;
	}

	async function getItemsInCategory(category) {
		let discountExclusions = ["DAD677", "DAD676", "DAD675", "DAD553", "DAD552", "DAD842", "DAD841", "DAD065", "DAD066", "DAD359", "DAD360"];
		const { data } = await axios.get(`${api_url}/api/db/products`);
		let items = [];
		for (let i = 0; i < data.length; i++) {
			if (data[i].category === category && !discountExclusions.includes(data[i].sku)) {
				items.push(data[i]);
			}
		}
		return items;
	}

	const categoryQuantityPriceBreakEnabled = true;
	const categoryDollarPriceBreakEnabled = true;
	//This function will check if the category items are equal to the category count
	async function setDiscounts() {
		let discountExclusions = ["DAD677", "DAD676", "DAD675", "DAD553", "DAD552", "DAD842", "DAD841", "DAD065", "DAD066", "DAD359", "DAD360"];
		let categories = getDistinctCategories();
		let counts = categoryCount();
		let items = [];
		for (let i = 0; i < categories.length; i++) {
			items.push(await getItemsInCategory(categories[i]));
		}
		if (categoryQuantityPriceBreakEnabled) {
			for (let i = 0; i < categories.length; i++) {
				//Switch statement to check the category[i] matches "Metal & Enamel Dice Set" ,"(D20) Metal & Enamel Dice" ,"Gemstone Dice Set", "Glass Dice Set" ,"(D6) Metal & Enamel Dice Set"
				switch (categories[i]) {
					case "Metal & Enamel Dice Set":
						discountNonExcluidedItems(i, 4);
						break;
					case "(D20) Metal & Enamel Dice":
						discountNonExcluidedItems(i, 4);
						break;
					case "Gemstone Dice Set":
						discountNonExcluidedItems(i, 4);
						break;
					case "Glass Dice Set":
						discountNonExcluidedItems(i, 4);
						break;
					case "Resin Polyhedral Dice Set":
						discountNonExcluidedItems(i, 6);
						break;
					case "(D6) Metal & Enamel Dice Set":
						discountNonExcluidedItems(i, 4);
						break;
					default:
						if (counts[i] >= items[i].length * 6) {
							for (let j = 0; j < cartItems.length; j++) {
								if (cartItems[j].category === categories[i]) {
									cartItems[j].discount = 0.5;
								}
							}
						} else {
							for (let j = 0; j < cartItems.length; j++) {
								if (cartItems[j].category === categories[i]) {
									cartItems[j].discount = 0.6;
								}
							}
						}
						break;
				}
			}
		}
		if (categoryDollarPriceBreakEnabled) {
			let dollarGoalForDiscount = 4000;
			let cartTotal = cartItems.reduce((a, c) => a + (discountExclusions.includes(c.sku) ? c.discount : 0.5) * c.retail_price * c.quantity, 0).toFixed(2);
			if (cartTotal >= dollarGoalForDiscount) {
				for (let i = 0; i < cartItems.length; i++) {
					if (!discountExclusions.includes(cartItems[i].sku)) {
						cartItems[i].discount = 0.5;
					}
				}
			} else {
				for (let i = 0; i < cartItems.length; i++) {
					if (!discountExclusions.includes(cartItems[i].sku)) {
						cartItems[i].discount = 0.6;
					}
				}
			}
		}
		//update elements with the classname unitCost item.price * item.discount
		var unitCosts = document.getElementsByClassName("unitCost");
		for (let i = 0; i < unitCosts.length; i++) {
			unitCosts[i].innerHTML = "$" + (cartItems[i].retail_price * cartItems[i].discount).toFixed(2);
		}
		var lineTotal = document.getElementsByClassName("lineTotal");
		for (let i = 0; i < lineTotal.length; i++) {
			lineTotal[i].innerHTML = "$" + (cartItems[i].retail_price * cartItems[i].discount * cartItems[i].quantity).toFixed(2);
		}

		//get the elememt with the id subtotal
		var subtotal = document.getElementById("subtotal");
		//refresh the var subtotal
		subtotal.innerHTML =
			//get number of items in the cart
			// "Subtotal (" + cartItems.length + " items): $" + getSubtotal().toFixed(2);
			"Subtotal (" + getNumItems() + " items): $" + getSubtotal().toFixed(2);

		function discountNonExcluidedItems(i, amountNeededForDiscount) {
			if (counts[i] >= items[i].length * amountNeededForDiscount) {
				for (let j = 0; j < cartItems.length; j++) {
					if (cartItems[j].category === categories[i]) {
						if (discountExclusions.includes(cartItems[j].sku)) {
							cartItems[j].discount = 0.6;
						} else {
							cartItems[j].discount = 0.5;
						}
					}
				}
			} else {
				for (let j = 0; j < cartItems.length; j++) {
					if (cartItems[j].category === categories[i]) {
						cartItems[j].discount = 0.6;
					}
				}
			}
		}
	}
	//define the function getSubtotal
	function getSubtotal() {
		var subtotal = 0;
		for (let i = 0; i < cartItems.length; i++) {
			subtotal += cartItems[i].retail_price * cartItems[i].discount * cartItems[i].quantity;
		}
		return subtotal;
	}

	//define the function getNumItems
	function getNumItems() {
		//define the var numItems
		var numItems = 0;
		//loop through each item in the cart
		for (let i = 0; i < cartItems.length; i++) {
			//add the item quantity to the numItems
			numItems += parseInt(cartItems[i].quantity);
			//numItems += cartItems[i].quantity;
		}
		//return the numItems
		return numItems;
	}

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setDiscounts().then(() => {
			setLoading(false);
		});
	}, []);

	const [clearPopup, setClearPopup] = useState(false);

	if (cartItems.length === 0) {
		return (
			<div>
				<Helmet>
					<title>Dice Habit | Shopping Cart</title>
				</Helmet>
				<div style={{ paddingTop: "6vh" }}></div>
				<div className="container-fluid">
					<div className="container-CartScreen-Checkout">
						<h3 style={{ "font-weight": "bolder" }}>Enter Customer Information</h3>
						<form onSubmit={sendEmail} style={{ width: "100%" }}>
							<div className="row">
								<div className="col">
									<div className="inputBox">
										<input tabIndex={100} type="text" name="store_name" placeholder="Store Name" required />
									</div>
									<div className="inputBox">
										<input tabIndex={104} type="text" name="address" placeholder="Street Address" />
									</div>
								</div>
								<div className="col">
									<div className="inputBox">
										<input tabIndex={101} type="text" name="contact_name" placeholder="Contact Name" required />
									</div>
									<div className="inputBox">
										<input tabIndex={105} type="text" name="city" placeholder="City" />
									</div>
								</div>
								<div className="col">
									<div className="inputBox">
										<input tabIndex={102} type="email" name="email" placeholder="Email" required />
									</div>

									<div className="flex">
										<div className="inputBox">
											<input tabIndex={106} type="text" name="state" placeholder="State" />
										</div>
										<div className="inputBox">
											<input tabIndex={107} type="text" name="zip_code" placeholder="Zip Code" />
										</div>
									</div>
								</div>
								<div className="col">
									<div className="inputBox">
										<input
											tabIndex={103}
											onChange={phoneNumberFormatter}
											type="telNO"
											id="phone-number"
											name="phone_number"
											size="20"
											minLength={9}
											maxLength={14}
											placeholder="Phone Number"
											required
											style={{ "margin-bottom": "5px" }}
										/>
									</div>
									<div className="inputBox">
										<textarea name="invoice" style={{ display: "none" }} defaultValue={orderText(cartItems)}></textarea>
										<textarea name="subtotal" style={{ display: "none" }} defaultValue={subtotal()}></textarea>
									</div>
									<div className="inputBox">
										<Button tabIndex={108} as="input" className="submit-btn" type="submit" value="Submit Order" disabled />
									</div>
								</div>
							</div>
						</form>
					</div>
					<div className="container-CartScreen-Checkout">
						<h4 className="Cart-Subtotal row center anchor" style={{ "font-weight": "bolder", "font-size": "26px" }} id="subtotal">
							Subtotal ({cartItems.reduce((a, c) => a + parseInt(c.quantity), 0)} items): ${cartItems.reduce((a, c) => a + c.retail_price * c.discount * c.quantity, 0).toFixed(2)}
						</h4>
					</div>
					<Alert style={{ "text-align": "center" }}>
						Cart is empty.{" "}
						<Link tabindex={-1} to="/BulkOrder">
							Go Shopping
						</Link>
					</Alert>
				</div>
			</div>
		);
	} else {
		setDiscounts();
		return (
			<div>
				<Helmet>
					<title>Dice Habit | Shopping Cart</title>
				</Helmet>
				{/* <ScrollToTop/> */}
				<div style={{ paddingTop: "6vh" }}></div>
				<div className="container-fluid">
					<div className="container-CartScreen-Checkout">
						<h3 style={{ "font-weight": "bolder" }}>Enter Customer Information</h3>
						<form onSubmit={sendEmail} style={{ width: "100%" }}>
							<div className="row">
								<div className="col">
									<div className="inputBox">
										<input tabIndex={100} type="text" name="store_name" placeholder="Store Name" required />
									</div>
									<div className="inputBox">
										<input tabIndex={104} type="text" name="address" placeholder="Street Address" />
									</div>
								</div>
								<div className="col">
									<div className="inputBox">
										<input tabIndex={101} type="text" name="contact_name" placeholder="Contact Name" required />
									</div>
									<div className="inputBox">
										<input tabIndex={105} type="text" name="city" placeholder="City" />
									</div>
								</div>
								<div className="col">
									<div className="inputBox">
										<input tabIndex={102} type="email" name="email" placeholder="Email" required />
									</div>

									<div className="flex">
										<div className="inputBox">
											<input tabIndex={106} type="text" name="state" placeholder="State" />
										</div>
										<div className="inputBox">
											<input tabIndex={107} type="text" name="zip_code" placeholder="Zip Code" />
										</div>
									</div>
								</div>
								<div className="col">
									<div className="inputBox">
										<input
											tabIndex={103}
											onChange={phoneNumberFormatter}
											type="telNO"
											id="phone-number"
											name="phone_number"
											size="20"
											minLength={9}
											maxLength={14}
											placeholder="Phone Number"
											required
											style={{ "margin-bottom": "5px" }}
										/>
									</div>
									<div className="inputBox">
										<textarea name="invoice" style={{ display: "none" }} defaultValue={orderText(cartItems)}></textarea>
										<textarea name="subtotal" style={{ display: "none" }} defaultValue={subtotal()}></textarea>
									</div>
									<div className="inputBox">
										{/* <Button tabIndex={108} as="input" className="submit-btn" type="submit" value="Submit Order"/> */}
										{/* <Button onClick={(e) => setButtonPopup(true)} */}
										<Button tabIndex={108} as="input" className="submit-btn" type="submit" value="Submit Order" />
										{/*                   <Popup 
                    trigger={buttonPopup} 
                    setTrigger={setButtonPopup}
                    >
                  </Popup> */}
									</div>
								</div>
							</div>
						</form>
					</div>
					<div className="container-CartScreen-Checkout">
						<div className="Cart-Subtotal vertical-align">
							<h4 className="row center" style={{ "font-weight": "bolder", "font-size": "26px" }} id="subtotal">
								Subtotal ({cartItems.reduce((a, c) => a + parseInt(c.quantity), 0)} items): ${cartItems.reduce((a, c) => a + c.retail_price * c.discount * c.quantity, 0).toFixed(2)}
							</h4>
							<ul className="right top colum-list">
								<Link to="/BulkOrder">
									<text className="width-fit">Add/Modify Order</text>
								</Link>

								<text onClick={setClearPopup}>Clear All</text>
							</ul>
						</div>
						{/* <button onClick={(e) => setButtonPopup(true)}>triger popup</button> */}
						{/* <button onClick={handelOrderSubmit}>triger popup</button> */}
						<PopupClear trigger={clearPopup} setTrigger={setClearPopup}></PopupClear>
					</div>
					<Table striped bordered>
						<tbody>
							<tr className="Category-Product-table-subHeader">
								<th>Image</th>
								<th>SKU</th>
								<th>Product Name</th>
								<th>Retail</th>
								<th>Unit Cost</th>
								<th>Quantity</th>
								<th>Total</th>
								<th></th>
							</tr>
							{cartItems.sort((a, b) => a.sku.localeCompare(b.sku)).map((item, index) => cartTabler(item))}
						</tbody>
					</Table>
				</div>
			</div>
		);
	}
}
