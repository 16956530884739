import React from 'react'
import { useContext } from "react";
import { Store } from "../Store";


function PopupClear(props) {

    function clearCartHandler(){
        ctxDispatch({type: "CART_CLEAR"})
        localStorage.removeItem('cartItems');
      }

    const { state, dispatch: ctxDispatch } = useContext(Store);
    const {
      cart: { cartItems },
    } = state;
    
  return (props.trigger) ? (
    <div className='popup'>
        <div className='popup-inner'>
            <h1>Are you sure you want to clear your cart?</h1>
            <button className='submit-btn' onClick={(e) => {props.setTrigger(false); clearCartHandler();}}>Yes</button>
            <button className='close-btn' onClick={() => props.setTrigger(false)}>No</button>
        </div>
    </div>
  ) : "";
}
export default PopupClear